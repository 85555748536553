import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { TiArrowUpThick, TiArrowDownThick } from "react-icons/ti";

const ScrollToTopOrBottom = () => {
  const { pathname } = useLocation();
  const [isTop, setIsTop] = useState(true); // Track if at the top of the page
  const [showButton, setShowButton] = useState(false); // Control button visibility

  useEffect(() => {
    // Scroll to the top when the pathname changes
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    const handleScroll = () => {
      const scrolledToBottom =
        window.innerHeight + window.scrollY >= document.body.offsetHeight;

      // Show the button when the user is not at the top or bottom
      setShowButton(window.scrollY > 0 && !scrolledToBottom);

      // Determine if the user is at the top or bottom
      setIsTop(window.scrollY === 0);
    };

    // Attach the scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleClick = () => {
    if (isTop) {
      // Smooth scroll to the bottom
      window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
    } else {
      // Smooth scroll to the top
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  return (
    <div className='flex items-center justify-center'>
      {showButton && (
        <button
          onClick={handleClick}
          className="fixed bottom-[7.7rem] left-[1rem] p-2 w-12 h-12 z-50 text-3xl bg-[#CD1412] text-white rounded-full shadow-lg transition-transform duration-300"
           
        >
           <TiArrowUpThick />
        </button>
      )}
    </div>
  );
};

export default ScrollToTopOrBottom;
