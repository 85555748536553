import  { useState, useRef, useEffect } from 'react';
import { motion } from 'framer-motion';
import { FaFacebookF, FaTwitter, FaInstagram, FaYoutube, FaLinkedin, FaDiscord } from 'react-icons/fa';
import { IoShareSocial } from "react-icons/io5";
import { Link } from 'react-router-dom';

const SocialMediaDropdown = () => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    // Add event listener
    document.addEventListener('mousedown', handleClickOutside);

    // Clean up event listener
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleDropdownClick = (event) => {
    event.stopPropagation();
    setIsOpen(!isOpen);
  };

  return (
    <div className="z-50">
      <div ref={ref} className="fixed bottom-[4.4rem] left-[1rem]">
        <button
          onClick={handleDropdownClick}
          className="p-3 bg-gradient-to-r w-12 h-12 border text-3xl bg-richblack-700 text-white rounded-full text-bold shadow-lg transform transition-transform duration-300 ease-in-out hover:scale-110 flex items-center justify-center"
        >
          <IoShareSocial />
        </button>

        {isOpen && (
          <motion.div
            className="absolute left-16 bottom-0 border border-richblack-100 bg-white p-4 rounded-xl shadow-xl space-y-2"
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.3 }}
          >
            <Link
              to="https://www.facebook.com/IdcIndiaOfficial/"
              className="flex items-center text-blue-200 hover:text-blue-800 transition-transform transform hover:scale-110"
            >
              <FaFacebookF size={24} className="mr-2" />
              Facebook
            </Link>
            <Link
              to="https://www.linkedin.com/company/idcindiaofficial/"
              className="flex items-center text-blue-400 hover:text-blue-600 transition-transform transform hover:scale-110"
            >
              <FaLinkedin size={24} className="mr-2" />
              LinkedIn
            </Link>
            <a
              href="https://twitter.com"
              className="flex items-center text-blue-100 hover:text-blue-500 transition-transform transform hover:scale-110"
            >
              <FaTwitter size={24} className="mr-2" />
              Twitter
            </a>
            <Link
              to="https://www.youtube.com/@idcindia."
              className="flex items-center text-[#CD1412] hover:text-[#852d2c] transition-transform transform hover:scale-110"
            >
              <FaYoutube size={24} className="mr-2" />
              YouTube
            </Link>
            <Link
              to="https://discord.gg/EMC4KdUv"
              className="flex items-center text-[#532181] hover:text-[#37204d] transition-transform transform hover:scale-110"
            >
              <FaDiscord size={24} className="mr-2" />
              Discord
            </Link>
          </motion.div>
        )}
      </div>
    </div>
  );
};

export default SocialMediaDropdown;
