import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  signupData: null,
  loading: false,
  token: localStorage.getItem("token") ? JSON.parse(localStorage.getItem("token")) : null,
};

const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    setSignupData(state, action) {
      state.signupData = action.payload;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setToken(state, action) {
      state.token = action.payload;
      localStorage.setItem("token", JSON.stringify(action.payload));
    },
    logout: (state) => {
      state.signupData = null; // Clear signup data
      state.loading = false;    // Reset loading state
      state.token = null;       // Clear token
      localStorage.removeItem("token"); // Remove token from localStorage
    },
  },
});

export const { setSignupData, setLoading, setToken, logout } = authSlice.actions; // Export logout as well

export default authSlice.reducer;
